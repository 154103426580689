<template>
  <Modal
    id="createDeliveriesFromInboxModal"
    :isOpen.sync="isModalOpen"
    :header="$t('schedule.uploadCSV')"
    size="is-medium"
    :loading="loading"
    isFullButtonWidth
    :buttonLabel="$t('global.continue')"
    @save="onSubmit"
    @cancel="() => {}"
  >
    <form ref="form" @submit.prevent="onSubmit">
      <Field class="w-50" required>
        <DatePicker
          position="is-bottom-right"
          :label="$t('schedule.search.date')"
          icon-right="calendar-today"
          :min-date="minDate"
          responseFormat="MM-DD-YYYY"
          v-model="formData.date"
          required
        />
      </Field>
      <CDPSelector v-model="formData.cdp_id" required setFirst />
      <FundingSourceSelector v-model="formData.founding_source_id" required keepFirst />
      <div class="flex">
        <FilePicker
          :title="$t('global.browse')"
          accept=".csv"
          apiUrl="/presigned/import/unattended_deliveries"
          :permission="Permission"
          :autosave="false"
          @file="(f) => (file = f)"
        />
        <span class="file-name" v-if="showFileName">
          {{ file.name }}
        </span>
      </div>
    </form>
  </Modal>
</template>

<script>
import { Field, Modal, DatePicker, FilePicker } from '@/components';
import FundingSourceSelector from '@/components/Selectors/FundingSourceSelector.vue';
import { Permissions } from '@/utils/Secure';
import { uploadFile } from '@/utils/Presigned';
import { toast } from '@/utils/dialog';
import CDPSelector from '../../../components/CDP/CDPSelector.vue';

export default {
  components: {
    Field,
    FilePicker,
    FundingSourceSelector,
    Modal,
    DatePicker,
    CDPSelector
  },
  data() {
    return {
      loading: { save: false },
      isModalOpen: false,
      formData: { date: new Date() },
      showFileName: false,
      file: null
    };
  },
  computed: {
    minDate() {
      return this.$moment().subtract(1, 'days').toDate();
    },
    Permission() {
      return Permissions.Imports.upload;
    },
    fileName() {
      if (!this.file) return null;
      const { date, cdp_id, founding_source_id } = this.formData;
      const userId = this.$store.getters.AUTH.id;
      const randomDigit = Math.round(Math.random() * 100);
      return `${this.getTimeDate(
        date
      )}_${randomDigit}_u${userId}_cdp${cdp_id}_${founding_source_id}`;
    }
  },
  methods: {
    async onSubmit() {
      if (this.validate()) {
        if (!this.file) this.$toast('danger', this.$t('global.upload.fileMissing'), 5000);
        else {
          this.loading.save = true;
          await this.saveFile(this.file, this.fileName);
          this.loading.save = false;
          this.resetModal();
          this.isModalOpen = false;
        }
      }
    },
    async saveFile(file, name) {
      const filePath = `import/unattended_deliveries/${file}.csv`;
      const newfilePath = filePath.split('/');

      try {
        this.loading.save = true;
        await uploadFile({
          fileName: `${name}.csv`,
          apiUrl: `/presigned/${newfilePath[0]}/${newfilePath[1]}`,
          originalFileName: file.name,
          file
        });
        toast('success', this.$t('global.upload.fileSuccess'), 5000);
        this.form = {};
        if (this.isModalOpen) this.isModalOpen = false;
      } catch (error) {
        console.log(error);
        this.$emit('fail');
      }
      this.loading.save = false;
    },
    validate() {
      let htmlValidator = false;
      if (this.formData.cdp_id && this.formData.founding_source_id) {
        if (this.$refs.form) {
          htmlValidator = this.$refs.form.checkValidity();
          if (!htmlValidator) this.$refs.form.reportValidity();
        }
      } else if (!this.file) this.$toast('danger', this.$t('global.upload.dataMissing'), 5000);
      return htmlValidator;
    },
    resetModal() {
      this.file = null;
      this.formData.date = this.$moment();
      this.showFileName = false;
    },
    getTimeDate(date) {
      if (typeof date === 'object') return this.$moment(date).format('MM-DD-YYYY_HHmmss');
      else return `${date}_000000`;
    }
  },
  watch: {
    isOpen(value) {
      this.isModalOpen = value;
    },
    isModalOpen(value) {
      if (value === false) this.resetModal();
      this.$emit('update:isOpen', value);
    },
    file(value) {
      if (value) {
        this.showFileName = true;
      }
    }
  },
  props: {
    isOpen: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
#createDeliveriesFromInboxModal
  .file-name
    width: 100% !important
  ::v-deep
    .modal-content-container
      .slot
        overflow: visible
  .w-50
    width: 50%
</style>
